<template>
  <main>
    <div class="view-checkout-confirm container">
      <div
        class="view-checkout-confirm__loader"
        v-if="loading"
      ><ui-loader /></div>

      <template v-else>
        <div class="view-checkout-confirm__header">
          <actions-button
            :appearance="$pepper.Appearance.SUBTLE"
            class="view-checkout-confirm__back-button"
            icon-pre="arrow-left"
            :size="$pepper.Size.S"
            @click="close">
            <span class="view-checkout-confirm__title">{{ $t('conn3ct-store.back_button') }}</span>
          </actions-button>

          <!-- <h3 class="view-checkout-confirm__title">{{ $t('conn3ct-store.back_button') }}</h3> -->
        </div>

        <!-- Success -->
        <section
          class="view-checkout-confirm__order"
          v-if="!isFailed && !loading">
          <article class="view-checkout-confirm__item view-checkout-confirm__intro">
            <h4 class="view-checkout-confirm__review-title">
              <ui-icon class="icon" glyph="check" />
              {{ $t('conn3ct-store.checkout_confirm_title', { service: service }) }}
            </h4>
          </article>

          <article class="view-checkout-confirm__item view-checkout-confirm__terms">
            <dl class="view-checkout-confirm__term view-checkout-confirm__code">
              <dt>{{ $t('conn3ct-store.checkout_confirm_code') }}</dt>
              <dd class="-code">{{ orderCode }}</dd>
            </dl>

            <dl
              class="view-checkout-confirm__term view-checkout-confirm__pm"
              v-if="paymentMethod">
              <dt>{{ $t('conn3ct-store.checkout_confirm_pm') }}</dt>

              <dd class="-pm">{{ paymentMethod }}</dd>
            </dl>
          </article>

          <!-- Cart -->
          <article
            class="view-checkout-confirm__item view-checkout-confirm__cart"
            v-if="hasItems">
            <dl
              class="view-checkout-confirm__cart-item"
              :key="$basil.uniqId(i)"
              v-for="(item, i) in items">
              <dt>
                <output>{{ item.quantity }}x</output>
                <span>{{ item.title }}</span>
                <span v-if="item.nft_serial">#{{ item.nft_serial }}</span>
              </dt>

              <dd>{{ toCurrency(item.total_price) }}</dd>
            </dl>

            <dl
              class="view-checkout-confirm__cart-item"
              :key="$basil.uniqId(i)"
              v-for="(fee, i) in fees">
              <dl>{{ fee.label }}</dl>
              <dd>{{ fee.value }}</dd>
            </dl>
          </article>

          <article
            class="view-checkout-confirm__item view-checkout-confirm__total"
            v-if="hasItems">
            <h4>{{ $t('conn3ct-store.cart_footer_total') }}</h4>

            <span>{{ toCurrency(total) }}</span>
          </article>
        </section>

        <!-- Error -->
        <section
          class="view-checkout-confirm__order"
          v-if="isFailed && !loading">
          <!-- 404 -->
          <article class="view-checkout-confirm__item">
            <h1 class="view-checkout-confirm__title">{{$t('conn3ct-store.checkout_failed_title')}}</h1>
            <h2 class="view-checkout-confirm__subtitle">{{ $t('conn3ct-store.checkout_failed_cart_title') }}</h2>
          </article>

          <article class="view-checkout-confirm__item flow">
            <p class="view-checkout-confirm__description">{{ $t('conn3ct-store.checkout_failed_message') }}</p>

            <actions-button
              :href="backHref"
              icon-pre="arrow-left"
            >{{ $t('conn3ct-store.back_button') }}</actions-button>
          </article>
        </section>

        <section
          class="view-checkout-confirm__social social"
          v-if="!isFailed && !loading">
          <div class="social__body">
            <h2 class="social__title">{{ $t('conn3ct-store.checkout_confirm_next_step_title') }}</h2>

            <span 
              class="social__description" 
              v-html="confirmDescription"
            ></span>
          </div>
        </section>
      </template>
    </div>
  </main>
</template>

<script>
import Bugsnag from '@bugsnag/js'

import CurrencyMixin from '@/helpers/currency'

import Config from '@/config'

export default {
  name: 'CheckoutConfirm',

  inject: [
    '$checkout',
    '$core',
    '$embed',
    '$localStorage',
    '$user',
  ],

  mixins: [
    CurrencyMixin
  ],

  data() {
    return {
      email: null,
      optin: true,
      signup: false,
      loading: true,
      error: null,
      errors: {},
      emailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    }
  },

  computed: {
    backHref() {
      return {
        name: 'sayl-front-catalog.catalog',
        params: {
          embed: this.$route.params.embed,
          service: this.$route.query.service
        }
      }
    },

    comment() {
      return this.$basil.get(this.order, 'comment')
    },

    confirmDescription() {
      let ret = this.$t('conn3ct-store.checkout_confirm_next_step_description', { url: this.walletURL })
      ret = ret.split('<a').join('<a class="view-checkout-confirm__link" ')
      return ret
    },

    fees() {
      let ret = []

      if(this.showVat) {
        ret.push({
          label: this.$t('conn3ct-store.cart_footer_vat'),
          value: this.toCurrency(this.vat)
        })
      }

      if(this.isDelivery) {
        let delivery = this.$basil.get(this.order, 'delivery_method.price', 0)
        if(delivery > 0) {
          ret.push({
            label: this.$t('conn3ct-store.delivery_fee'),
            value: this.toCurrency(delivery)
          })
        }
      } 

      if(this.orderServiceFees) {
        ret.push({
          label: this.$t('conn3ct-store.service_fee'),
          value: this.toCurrency(this.orderServiceFees)
        })
      }

      if(this.orderTransactionFees) {
        ret.push({
          label: this.$t('conn3ct-store.transaction_fee'),
          value: this.toCurrency(this.orderTransactionFees)
        })
      }

      if(this.serviceFees) {
        ret.push(this.serviceFees)
      }

      if(!!this.voucher) {
        ret.push({
          label: this.voucher.name, //this.$t('conn3ct-store.cart_footer_voucher'),
          value: this.toCurrency(this.voucher.amount_discount * -1)
        })
      }

      if(this.hasCredits) {
        ret.push({
          label: this.$t('conn3ct-store.cart_footer_fidelity'),
          value: this.toCurrency((-1) * this.credits)
        })
      }

      return ret
    },

    hasItems() {
      return this.items.length > 0
    },

    isFailed() {
      return this.$basil.get(this.order, 'isFailed', true)
    },

    items() {
      return this.$basil.get(this.order, 'order_items', [])
    },

    order() {
      return this.$checkout.order
    },

    paymentMethod() {
      let ret = null

      let slug = this.$basil.get(this.order, 'payment_method')
      if (!this.$basil.isNil(slug)) {
        ret = this.$t(`sayl-front.payment_methods_${slug}`)
      }

      return ret
    },

    service() {
      return this.$t(`sayl-front.service_${this.serviceName}`)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    optinAlreadyAccepted() {
      return this.$basil.get(this.user, 'optIn', false)
    },

    orderCode() {
      return this.$basil.get(this.order, 'pickup_code', '[DEV] - CODE')
    },

    orderService() {
      return this.$basil.get(this.order, 'service')
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    socials() {
      let ret = []

      if (!this.loading && this.shop.social) {
        ret = this.shop.social
        ret.forEach( s => {
          s.icon = `${Config.cdn}statics/images/icons/social/${s.type}.svg`
          s.label = this.$t(`sayl-front.social_${s.type}_cta`)
        })
      }

      return ret
    },

    total() {
      return this.$basil.get(this.order, 'price_total', 0) + this.$basil.get(this.selectedServiceFees, 'fees', 0)
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    voucher() {
      return this.$basil.get(this.order, 'voucher')
    },

    walletURL() {
      return this.$basil.get(Config, 'wallet.url', 'https://dev.wallet.infinity-commerce.io/login')
    }
  },

  methods: {
    close() {
      this.$router.push({ 
        name: 'sayl-front-catalog.catalog', 
        params: { 
          embed: this.$route.params.embed
        }
      }).catch(() => {})
    },

    async onSignup() {
      this.errors = {}

      if(!this.emailPattern.test(this.email) || this.email == null) {
        this.errors['email'] = [this.$t('conn3ct-store.email_not_valid')]
        return
      }

      this.user.email = this.email
      this.user.optIn = true

      try {
        await this.$user.update({})
        this.signup = true
      } catch(e) {
        Bugsnag.notify(e)
      }
    },
  },

  async mounted() {
    this.loading = true
    this.$bus.$emit('injekt.sp.confirm')

    // No confirmation id
    if(this.$basil.isNil(this.$route.params.id)) {
      this.$router.push({name: 'sayl-front-catalog.catalog'}).catch(() => {})
      return
    }

    this.$localStorage.cart = null
    this.$localStorage['cart-service'] = null

    try {
      await this.$checkout.view({ orderId: this.$route.params.id })
      if(this.user.isLogged) {
        await this.user.reset()
      } 
    } catch(e) {
      this.error = e
      $console.error(e)
    } finally {
      this.loading = false
    }
  }
}
</script>
